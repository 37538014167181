import { useCompanyLookupsList, useRegionLookupsList } from '@/api';
import { CardTransactionStatus } from '@/api/base/model';
import { useCardTransactionList } from '@/api/methods/cardTransactions';
import { Module, TableColumnType } from '@/components';
import { ApiKey } from '@/enums';
import { colorGreenLight } from '@/styles';

export const CardTransactions = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.CardTransactions,
        translationKey: 'CardTransactions',
        useApiList: useCardTransactionList,
        sort: 'id',
        isSortAscending: false,
        columns: [
          {
            dataKey: 'id',
            label: 'Identifier',
          },
          {
            dataKey: 'transactionProcessingDate',
            filterKey: 'transactionProcessingDate',
            label: 'TransactionProcessingDate',
            type: TableColumnType.Date,
            filterEnabled: true,
          },
          {
            dataKey: 'amount',
            label: 'Amount',
            type: TableColumnType.Currency,
          },
          {
            dataKey: 'status',
            filterKey: 'status',
            label: 'Status',
            type: TableColumnType.Enum,
            enumName: 'CardTransactionStatus',
            enumObject: CardTransactionStatus,
            filterEnabled: true,
          },
          {
            dataKey: 'worker.name',
            filterKey: 'workerName',
            label: 'Worker',
            type: TableColumnType.Text,
            filterEnabled: true,
          },
          {
            dataKey: 'company.name',
            filterKey: 'companyId',
            label: 'Company',
            type: TableColumnType.ApiEnum,
            useApiList: useCompanyLookupsList,
            filterEnabled: true,
          },
          {
            dataKey: 'payMonth',
            label: 'PayMonth',
            type: TableColumnType.Month,
            filterEnabled: true,
          },
          {
            dataKey: 'regionCode',
            label: 'Region',
            filterKey: 'regionId',
            type: TableColumnType.ApiEnum,
            useApiList: useRegionLookupsList,
            filterEnabled: true,
          },
          {
            dataKey: 'cashbackAmount',
            label: 'CashbackAmount',
            type: TableColumnType.Currency,
          },
          {
            dataKey: 'cashbackPayoutDate',
            filterKey: 'cashbackPayoutDate',
            label: 'CashbackPayoutDate',
            type: TableColumnType.Date,
            filterEnabled: true,
          },
          {
            dataKey: 'cashbackPayoutId',
            label: 'CashbackPayoutId',
            getStyle: (_, item) =>
              item.cashbackPayoutId ? { backgroundColor: colorGreenLight } : {},
          },
          {
            dataKey: 'directDebitId',
            label: 'Debited',
            getStyle: (_, item) => (item.directDebitId ? { backgroundColor: colorGreenLight } : {}),
          },
          {
            dataKey: 'cardNumber',
            filterKey: 'cardNumber',
            label: 'CardNumber',
            type: TableColumnType.Text,
            filterEnabled: true,
          },
        ],
        columnForTotal: 'amount',
      }}
    />
  );
};
