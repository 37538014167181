import {
  useCompanyLookupsList,
  useDirectDebitGet,
  useDirectDebitList,
  useDirectDebitPaymentsExport,
  useDirectDebitRequest,
  useDirectDebitProcess,
  useReconciliatePayments,
  useRegionLookupsList,
  useDirectDebitsRequestSk,
} from '@/api';
import { DirectDebitStatus } from '@/api/base/model';
import { Module, TableColumnType } from '@/components';
import { ApiKey, Permission } from '@/enums';
import {
  AccountBalanceOutlined,
  CheckCircleOutline,
  DownloadForOffline,
} from '@mui/icons-material';
import { FormBasic, formBasicFields } from './FormBasic';
import {
  useApiCeskaSporitelnaVerifyConnection,
  useApiCeskaSporitelnaVerifiedConnectionConnect,
  useApiCeskaSporitelnaVerifiedConnectionDirectDebit,
} from '@/api/methods/ceskaSporitelna';
import { useAppContext } from '@/context';

export const DirectDebits = () => {
  const { hasPermission } = useAppContext();

  return (
    <Module
      {...{
        useReconciliatePayments: hasPermission(Permission.DirectDebitsSave)
          ? useReconciliatePayments
          : undefined,
        useApiVerifyConnection: hasPermission(Permission.DirectDebitsSave)
          ? useApiCeskaSporitelnaVerifyConnection
          : undefined,
        useApiVerifiedConnectionConnect: hasPermission(Permission.DirectDebitsSave)
          ? useApiCeskaSporitelnaVerifiedConnectionConnect
          : undefined,
        useApiVerifiedConnectionDirectDebit: hasPermission(Permission.DirectDebitsSave)
          ? useApiCeskaSporitelnaVerifiedConnectionDirectDebit
          : undefined,
        useApiGenerateFiles: hasPermission(Permission.DirectDebitsSave)
          ? useDirectDebitsRequestSk
          : undefined,
        apiKey: ApiKey.DirectDebits,
        translationKey: 'DirectDebits',
        useApiList: useDirectDebitList,
        useApiGet: useDirectDebitGet,
        formComponentHeight: 300,
        sort: 'id',
        isSortAscending: false,
        columns: [
          {
            dataKey: 'id',
            label: 'Identifier',
          },
          {
            dataKey: 'status',
            filterKey: 'status',
            label: 'Status',
            type: TableColumnType.Enum,
            enumName: 'DirectDebitStatus',
            enumObject: DirectDebitStatus,
            filterEnabled: true,
          },
          {
            dataKey: 'companyName',
            filterKey: 'companyId',
            label: 'Company',
            type: TableColumnType.ApiEnum,
            useApiList: useCompanyLookupsList,
            filterEnabled: true,
          },
          {
            dataKey: 'regionCode',
            label: 'Region',
            filterKey: 'regionId',
            type: TableColumnType.ApiEnum,
            useApiList: useRegionLookupsList,
            filterEnabled: true,
          },
          {
            dataKey: 'payMonth',
            label: 'PayMonth',
            type: TableColumnType.Month,
            filterEnabled: true,
          },
          { dataKey: 'companyCode', label: 'CompanyCode' },
          {
            dataKey: 'amount',
            label: 'Amount',
            type: TableColumnType.Currency,
          },
          {
            dataKey: 'created',
            label: 'Created',
            type: TableColumnType.DateTime,
            filterType: TableColumnType.Date,
            filterEnabled: true,
          },
        ],
        columnForTotal: 'amount',
        formComponents: [
          {
            label: 'DirectDebit',
            component: FormBasic,
            fields: formBasicFields,
          },
        ],
        actions: [
          {
            label: 'DirectDebitProcess',
            text: 'DirectDebitProcessConfirmText',
            message: 'DirectDebitProcessSuccess',
            useAction: useDirectDebitProcess,
            isEnabled: (p) => p.status === DirectDebitStatus.Requested,
            icon: CheckCircleOutline,
            permission: Permission.DirectDebitsSave,
          },
          {
            label: 'DirectDebitRequest',
            text: 'DirectDebitRequestConfirmText',
            message: 'DirectDebitRequestSuccess',
            useAction: useDirectDebitRequest,
            isEnabled: (p) => p.status === DirectDebitStatus.Pending,
            icon: AccountBalanceOutlined,
            permission: Permission.DirectDebitsSave,
          },
          {
            key: 'xls',
            label: 'DownloadXlsx',
            text: 'DownloadXlsxText',
            useAction: useDirectDebitPaymentsExport,
            icon: DownloadForOffline,
            message: 'DownloadCompleted',
          },
        ],
      }}
    />
  );
};
