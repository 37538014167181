import {
  Box,
  TableContainer,
  Table as MuiTable,
  styled,
  TableFooter,
  TableCell,
  TableRow,
} from '@mui/material';
import { useMemo } from 'react';
import { Spinner } from '../Loading';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import { TablePagination } from './TablePagination';
import { Props } from './types';

export const Table = ({
  data,
  columns: allColumns,
  columnForTotal,
  loading,
  rowClickEnabled,
  sortBy,
  isSortAscending,
  filter,
  offset,
  limit,
  count,
  componentKey,
  onSort,
  onFilter,
  onRowClick,
  onOffsetChange,
  onLimitChange,
}: Props) => {
  const columns = useMemo(
    () => allColumns.filter((c) => c.visible === undefined || c.visible),
    [allColumns],
  );

  const totalValue = useMemo(() => {
    return columnForTotal && data.length > 0 && data[0][columnForTotal]
      ? data.map((x) => parseFloat(x[columnForTotal])).reduce((acc, current) => acc + current)
      : 0;
  }, [data, columnForTotal]);

  return (
    <TableWrapper>
      <StyledTableContainer>
        <MuiTable>
          <TableHead {...{ columns, filter, onFilter, sortBy, isSortAscending, onSort }} />
          <TableBody {...{ data, columns, componentKey, rowClickEnabled, onRowClick }} />
          <TableFooter>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>{totalValue}</TableCell>
            </TableRow>
          </TableFooter>
        </MuiTable>
        <Spinner {...{ fullSize: true, visible: loading }} />
      </StyledTableContainer>
      <TablePagination {...{ offset, limit, count, onOffsetChange, onLimitChange }} />
    </TableWrapper>
  );
};

const TableWrapper = styled(Box)({
  width: '100%',
  minWidth: '100%',
  maxWidth: '100%',
  height: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

const StyledTableContainer = styled(TableContainer)({
  flex: 1,
});
