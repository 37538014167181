import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { Table as TableComponent, Paging, Sort, Filter, FilterValue } from '../Table';
import { TableProps } from './types';
import { useModule } from './useModule';
import { useApiListData } from '@/utils';
import { identity, pickBy } from 'lodash';
import { createActions } from './Actions';
import { TableToolbar } from './TableToolbar';
import { TableExport } from './TableExport';
import { TableVerifiedConnect } from './TableVerifiedConnect';
import { TableGenerateFiles } from './TableGenerateFiles';
import { TableDirectDebit } from './TableDirectDebit';

export const Table = ({
  columns,
  columnForTotal,
  actions,
  trailingActions,
  translationKey,
  sort: defaultSort,
  isSortAscending = false,
  componentKey,
  useApiList,
  onSuccess,
  useApiExport,
  useApiGenerateFiles,
  useApiVerifyConnection,
  useApiVerifiedConnectionConnect,
  useApiVerifiedConnectionBatchProcess,
  useReconciliatePayments,
  useApiPollBatches,
  useApiVerifiedConnectionDirectDebit,
  ...props
}: TableProps) => {
  const { t } = useTranslation(translationKey);
  const { editEnabled, onTableRowClick } = useModule();

  const [filter, setFilter] = useState<Filter>({});

  const [sort, setSort] = useState<Sort>({
    sortBy: defaultSort ?? 'id',
    isSortAscending,
  });

  const [paging, setPaging] = useState<Paging>({ offset: 0, limit: 25 });

  const apiOptions = useMemo(
    () => ({
      ...filter,
      ...sort,
      ...paging,
    }),
    [filter, sort, paging],
  );

  const {
    data: { data, metadata },
    isLoading: loading,
  } = useApiListData(useApiList, apiOptions);

  const handleFilter = useCallback((key: string, value: FilterValue) => {
    setFilter((filter) => pickBy({ ...filter, [key]: value }, identity));
  }, []);

  const handleSort = useCallback((sortBy: string, isSortAscending: boolean) => {
    setSort({ sortBy, isSortAscending });
  }, []);

  const handleOffset = useCallback((offset: number) => {
    setPaging((paging) => ({ ...paging, offset }));
  }, []);

  const handleLimit = useCallback((limit: number) => {
    setPaging((paging) => ({ ...paging, limit, offset: 0 }));
  }, []);

  const columnsWithActions = useMemo(
    () => [
      ...createActions({ actions, editEnabled, onSuccess, t }),
      ...columns.map(({ label, ...rest }) => ({ label: t(label), ...rest })),
      ...createActions({
        actions: trailingActions,
        editEnabled,
        onSuccess,
        t,
        dataKey: 'trailingActions',
      }),
    ],
    [columns, actions, trailingActions, editEnabled, onSuccess, t],
  );

  return (
    <>
      {useApiExport && <TableExport {...{ useApiExport, apiOptions }} />}
      {useApiGenerateFiles && <TableGenerateFiles {...{ useApiGenerateFiles, apiOptions }} />}
      {useApiVerifyConnection &&
        useApiVerifiedConnectionConnect &&
        useApiVerifiedConnectionBatchProcess &&
        useReconciliatePayments &&
        useApiPollBatches && (
          <TableVerifiedConnect
            {...{
              t,
              useApiVerifyConnection,
              useApiVerifiedConnectionConnect,
              useApiVerifiedConnectionBatchProcess,
              useReconciliatePayments,
              useApiPollBatches,
            }}
          />
        )}
      {useApiVerifyConnection &&
        useApiVerifiedConnectionConnect &&
        useApiVerifiedConnectionDirectDebit && (
          <TableDirectDebit
            {...{
              t,
              useApiVerifyConnection,
              useApiVerifiedConnectionConnect,
              useApiVerifiedConnectionDirectDebit,
            }}
          />
        )}
      <TableToolbar />
      <TableComponent
        {...{
          data,
          count: metadata.totalItems,
          loading,
          columns: columnsWithActions,
          onRowClick: onTableRowClick,
          onFilter: handleFilter,
          onSort: handleSort,
          onOffsetChange: handleOffset,
          onLimitChange: handleLimit,
          componentKey,
          filter,
          columnForTotal,
          ...sort,
          ...paging,
          ...props,
        }}
      />
    </>
  );
};
