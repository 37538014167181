import { Modal, Table, TableColumnType } from '@/components';
import { CheckCircleOutline } from '@mui/icons-material';
import {
  useApiCeskaSportelnaCzAuthorizationstList,
  useApiCeskaSporitelnaBatchAuthorizationUrlBatch,
  useApiCeskaSporitelnaBatchAuthorizationUrlSinglePayment,
} from '@/api/methods/ceskaSporitelna';
import { useApiListData } from '@/utils';
import { ExternalPaymentState } from '@/api/base/model/external-payment-state';
import { createActions } from '@/components/Module/Actions';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import { useQueryClient } from 'react-query';
import { ApiKey } from '@/enums';

export const BankAuthorizations = () => {
  const { t } = useTranslation('BankAuthorizations');

  const queryClient = useQueryClient();
  const {
    data: { data, metadata },
    isLoading: loading,
  } = useApiListData(useApiCeskaSportelnaCzAuthorizationstList, {});

  const batchCallback = useApiCeskaSporitelnaBatchAuthorizationUrlBatch();
  const singlePaymentCallback = useApiCeskaSporitelnaBatchAuthorizationUrlSinglePayment();
  const actions = createActions({
    actions: [
      {
        label: 'Authorize',
        icon: CheckCircleOutline,
        onClick: (id) => {
          return batchCallback.mutateAsync(id);
        },
        confirmDisabled: true,
        isEnabled: (data) => data.isBatchPayment === true,
      },
      {
        label: 'Authorize',
        icon: CheckCircleOutline,
        onClick: (id) => {
          return singlePaymentCallback.mutateAsync(id);
        },
        confirmDisabled: true,
        isEnabled: (data) => data.isBatchPayment === false,
      },
    ],
    editEnabled: false,
    onSuccess: (a, b) => {
      console.log(a, b);
    },
    t,
  });

  const columns = [
    {
      dataKey: 'id',
      label: t('BatchId'),
    },
    {
      dataKey: 'authorizationState',
      type: TableColumnType.Enum,
      label: t('AuthorizationState'),
      enumName: 'ExternalPaymentState',
      enumObject: ExternalPaymentState,
    },
    {
      dataKey: 'paymentsCount',
      type: TableColumnType.Number,
      label: t('PaymentsCount'),
    },
    {
      dataKey: 'totalAmount',
      type: TableColumnType.Currency,
      label: t('TotalAmount'),
    },
  ];

  return (
    <>
      <Modal
        {...{
          open: batchCallback.isSuccess,
          onClose: () => {
            batchCallback.reset();
            queryClient.invalidateQueries(ApiKey.BankAuthorizations);
          },
          maxWidth: 'lg',
        }}
      >
        <Content>
          <iframe width={'1000px'} height={'800px'} src={batchCallback.data} />
        </Content>
      </Modal>
      <Modal
        {...{
          open: singlePaymentCallback.isSuccess,
          onClose: () => {
            singlePaymentCallback.reset();
            queryClient.invalidateQueries(ApiKey.BankAuthorizations);
          },
          maxWidth: 'lg',
        }}
      >
        <Content>
          <iframe width={'1000px'} height={'800px'} src={singlePaymentCallback.data} />
        </Content>
      </Modal>
      <Table
        {...{
          data: data,
          columns: [...actions, ...columns],
          columnForTotal: 'totalAmount',
          count: metadata.totalItems,
          loading: loading,
          filter: {},
          onFilter: (a, b) => {
            console.log(a, b);
          },
          onSort: (a, b) => {
            console.log(a, b);
          },
          sortBy: 'id',
          isSortAscending: false,
          onOffsetChange: (a) => {
            console.log(a);
          },
          onLimitChange: (a) => {
            console.log(a);
          },
          offset: 0,
          limit: 1000,
          rowClickEnabled: false,
          componentKey: 'BankAuthorizations',
        }}
      />
    </>
  );
};

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));
