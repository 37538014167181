import {
  useBalanceErpPaymentsExport,
  useBalancePaymentsExport,
  useBalanceExport,
  useBalanceGet,
  useBalanceList,
  useCompanyLookupsList,
  useRegionLookupsList,
} from '@/api';
import { Module, TableColumnType } from '@/components';
import { ApiKey } from '@/enums';
import { CloudDownload, DownloadForOffline } from '@mui/icons-material';
import { FormBasic, formBasicFields } from './FormBasic';

export const Balances = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.Balances,
        translationKey: 'Balances',
        useApiList: useBalanceList,
        useApiGet: useBalanceGet,
        useApiExport: useBalanceExport,
        formComponentHeight: 490,
        columns: [
          { dataKey: 'id', label: 'Identifier', type: TableColumnType.Number },
          {
            dataKey: 'payMonth',
            label: 'PayMonth',
            type: TableColumnType.Month,
            filterEnabled: true,
          },
          {
            dataKey: 'companyName',
            label: 'CompanyName',
            filterKey: 'companyId',
            type: TableColumnType.ApiEnum,
            useApiList: useCompanyLookupsList,
            filterEnabled: true,
          },
          { dataKey: 'companyCode', label: 'CompanyCode' },
          {
            dataKey: 'companyRegionCode',
            label: 'Region',
            filterKey: 'regionId',
            type: TableColumnType.ApiEnum,
            useApiList: useRegionLookupsList,
            filterEnabled: true,
          },
          { dataKey: 'totalPaid', label: 'TotalPaid', type: TableColumnType.Currency },
          { dataKey: 'totalBonus', label: 'TotalBonus', type: TableColumnType.Currency },
          { dataKey: 'totalFee', label: 'TotalFee', type: TableColumnType.Currency },
          { dataKey: 'totalAdvance', label: 'TotalAdvance', type: TableColumnType.Currency },
          { dataKey: 'totalCompanyFee', label: 'TotalCompanyFee', type: TableColumnType.Currency },
          { dataKey: 'totalWorkerFee', label: 'TotalWorkerFee', type: TableColumnType.Currency },
          {
            dataKey: 'totalPaymentsCount',
            label: 'TotalPaymentsCount',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'totalWorkersCount',
            label: 'TotalWorkersCount',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'totalWorkersRegistered',
            label: 'TotalWorkersRegistered',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'totalWorkersActive',
            label: 'TotalWorkersActive',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'totalWorkersPaid',
            label: 'TotalWorkersPaid',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'dateTimeCalculated',
            label: 'DateTimeCalculated',
            type: TableColumnType.DateTime,
          },
          {
            dataKey: 'dateTimeDownloaded',
            label: 'DateTimeDownloaded',
            type: TableColumnType.DateTime,
          },
        ],
        columnForTotal: 'totalAdvance',
        formComponents: [
          {
            label: 'Balance',
            component: FormBasic,
            fields: formBasicFields,
          },
        ],
        actions: [
          {
            key: 'xls',
            label: 'DownloadXls',
            text: 'DownloadXlsText',
            useAction: useBalancePaymentsExport,
            icon: DownloadForOffline,
            message: 'DownloadCompleted',
          },
          {
            key: 'erpSystem',
            label: (item, t) => t('DownloadErpSystem', { erpSystem: item.erpSystem }),
            isEnabled: (item) => item.erpSystem,
            text: 'DownloadErpSystemText',
            useAction: useBalanceErpPaymentsExport,
            icon: CloudDownload,
            message: 'DownloadCompleted',
          },
        ],
      }}
    />
  );
};
